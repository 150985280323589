<template>
  <div id="preview-mapa-setores"></div>
</template>

<script>
import mapaSetorizacaoMixins from '@/components/analises-setorizadas/mixins/mapaSetorizacaoMixins';

export default {
  mixins: [mapaSetorizacaoMixins],

  props: {
    setorizacaoId: {
      type: Number
    }
  },

  data() {
    return {
      mapaId: 'preview-mapa-setores'
    };
  },

  mounted() {
    this.configuraMapa(7);

    if (this.setorizacaoId) {
      this.getSetorizacao(this.setorizacaoId);
      setTimeout(() => {
        this.getSetores(this.setorizacaoId);
      }, 1000);
    }
  },

  watch: {
    setorizacaoId() {
      if (this.setorizacaoId) {
        this.getSetorizacao(this.setorizacaoId);
        setTimeout(() => {
          this.getSetores(this.setorizacaoId);
        }, 1000);
      }
    }
  }
};
</script>

<style>
#preview-mapa-setores {
  height: 300px;
  z-index: 0;
}
</style>
